import React from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import BlobManager from "./BlobManager";
import "./App.css";

const App = () => {
  return (
    <MsalAuthenticationTemplate interactionType="redirect" authenticationRequest={loginRequest}>
      <BlobManager />
    </MsalAuthenticationTemplate>
  );
};

export default App;
