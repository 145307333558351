import React, { useState, useEffect, useCallback } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { MSALTokenCredential } from "./MSALTokenCredential";
import ConfigEditor from "./ConfigEditor";
import {generateConfigFromOnnxFile} from "./ModelParser";

const BlobManager = () => {
  const { instance, accounts } = useMsal();
  const [blobList, setBlobList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [blobServiceClient, setBlobServiceClient] = useState(null);
  const [isConfigCorrect, setIsConfigCorrect] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  
  const [config, setConfig] = useState("");
  const [modelName, setModelName] = useState("None");
  const directoryScheme = `
${modelName}
├── 1
│   └── model.onnx
└── config.pbtxt
`;

  const accountName = "staimodelshrkrc";
  const containerName = "dev";
  const account = accounts[0];
  const userId = account?.username.split('@')[0];

  const listBlobs = useCallback(async () => {
    if (!blobServiceClient) return;

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobs = [];

    try {
      for await (const blob of containerClient.listBlobsFlat()) {
        if (!blob.name.includes(userId)) {
          continue;
        }
        blobs.push(blob.name);
      }
      setBlobList(blobs);
    } catch (error) {
      console.error("Error listing blobs:", error.message);
    }
  }, [blobServiceClient, userId]);
 
  const uploadBlob = async () => {
    if (!selectedFile || !blobServiceClient || !isConfigCorrect) return;

    const containerClient = blobServiceClient.getContainerClient(containerName);

    try {
      setUploadProgress(0);

      const configFileName = `${modelName}/config.pbtxt`;
      const configBlobClient = containerClient.getBlockBlobClient(configFileName);
      await configBlobClient.upload(config, config.length, {
        onProgress: (progress) => {
          const percentage = Math.round((progress.loadedBytes / selectedFile.size) * 100);
          setUploadProgress(percentage);
        },
      });

      setUploadProgress(100);

      setUploadProgress(0);

      const modelFileName = `${modelName}/1/model.onnx`;
      const modelBlobClient = containerClient.getBlockBlobClient(modelFileName);
      await modelBlobClient.uploadBrowserData(selectedFile, {
        onProgress: (progress) => {
          const percentage = Math.round((progress.loadedBytes / selectedFile.size) * 100);
          setUploadProgress(percentage);
        },
      });

      setUploadProgress(100);

      listBlobs();
    } catch (error) {
      console.error("Error uploading blob:", error.message);
    }
  };

  const deleteBlob = async (blobName) => {
    if (!blobServiceClient) return;

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    try {
      await blockBlobClient.delete();
      listBlobs();
    } catch (error) {
      console.error("Error deleting blob:", error.message);
    }
  };

  useEffect(() => {
    const request = {
      ...loginRequest,
      account,
    };

    instance
      .acquireTokenSilent(request)
      .then(() => {
        const tokenCredential = new MSALTokenCredential(instance, account, [
          "https://storage.azure.com/.default",
        ]);

        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net`,
          tokenCredential
        );
        setBlobServiceClient(blobServiceClient);
      })
      .catch((error) => {
        console.error("Token acquisition failed:", error);
      });
  }, [instance, account]);

  useEffect(() => {
    listBlobs();
  }, [listBlobs]);

  useEffect(() => {
    const parseONNXFile = async () => {
      try {
        const file = selectedFile;
        if (!file) {
          return;
        }

        const initialConfig = await generateConfigFromOnnxFile(file);
        setConfig(initialConfig);
        setModelName(initialConfig.split('"')[1]);
      } catch (error) {
        console.error('Error parsing ONNX file:', error);
      }
    };

    parseONNXFile();
  }, [selectedFile]);

  return (
    <div>
      <h2>Blob Manager</h2>
      <button onClick={listBlobs}>Refresh Blobs</button>
      <ul>
        {blobList.map((blob) => (
          <li key={blob}>
            {blob}
            <button onClick={() => deleteBlob(blob)}>Delete</button>
          </li>
        ))}
      </ul>
      <input type="file" accept=".onnx" onChange={(e) => setSelectedFile(e.target.files[0])} />
      <br/>
      <br/>
      <ConfigEditor userId={userId} config={config} setConfig={setConfig} setIsConfigCorrect={setIsConfigCorrect} setModelName={setModelName} />
      {config && (
        <div>
          <pre>{directoryScheme}</pre>
        </div>
      )}
      <div>
        {!isConfigCorrect && (
          <div>
            <pre>Incorrect configuration!</pre>
            <pre>Fix configuration in Config Editor to upload</pre>
          </div>
        )}
        {isConfigCorrect && (
          <button onClick={uploadBlob}>Upload</button>
        )}
        {uploadProgress > 0 && (
          <div>
            <p>Upload Progress: {uploadProgress}%</p>
            <progress value={uploadProgress} max="100"></progress>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlobManager;
