import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  // App Registration: [tf]dev-ai-model-mgmt
  auth: {
    clientId: "f4e8a4b6-b3b9-4d48-a7e8-01d2774ddaeb",
    authority: "https://login.microsoftonline.com/3c05c1e1-649c-4637-8700-993b1f71d341",
    redirectUri: "https://ai-model-mgmt.dev-dentbird.com",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["https://storage.azure.com/user_impersonation"],
};
